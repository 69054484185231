@import '@/styles/_shared.scss';

.mobileMenuEnter {
  transform: translate(100%);
}
.mobileMenuEnterActive {
  transform: translate(0);
}
.mobileMenuEnterDone {
  transform: translate(0);
}
.mobileMenuExit {
  transform: translate(100%);
}

.mobileMenu {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $sitkablue;
  color: $alabaster2;
  z-index: 2000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  height: 100%;
  padding: 24px 0;
  transition: 0.2s ease-in-out transform;
  p:last-of-type {
    margin-bottom: 24px;
  }
  ul {
    list-style: none;
    margin: 12px 0;
  }
  li {
    margin: 0 0 24px;
    &:last-child {
      margin: 0;
    }
    a {
      font-size: 14px;
    }
  }
  a {
    color: $alabaster2;
    text-decoration: none;
    &:hover:not(.mobileMainNavBtn) {
      color: $salmon;
      text-decoration: underline;
    }
  }
  .mobilePrimaryNavItem {
    margin: 0 0 30px;
    &:last-child {
      margin: 0;
    }
    a {
      font-size: 18px;
    }
  }

  .mobilePrimaryNavItemIndented {
    margin: 0 0 30px;
    padding: 0 15px;
    a {
      font-size: 18px;
    }
  }
}

.mobileMainNavBtn {
  max-width: 286px;
}

.mobileMenuCloseBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: $alabaster2;
  border-radius: 100%;
  border: none;
  width: 24px;
  height: 24px;
  padding: 0;
  svg {
    width: 14px;
    height: 14px;
    * {
      fill: $sitkablue;
    }
  }
}

.mobileMenuSection {
  padding: 24px 0;
  border-bottom: 2px solid rgba(255,253,252, 0.25);
  &:last-child {
    border-bottom: none;
  }
}